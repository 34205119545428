import dynamic from 'next/dynamic'
import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'solito/link'

import { Box, Container, Grid } from '@mui/material'

import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'
import SocialLinksBlock from 'app/components/common/SocialLinksBlock'
import { useGetAllStaticPagesQuery } from 'app/services/Query/static-pages/static-pages.query'
import { ADDITIONAL_STATIC_PAGES } from 'app/utils/constants/staticPage.constants'
import { STATIC_PAGE_VISIBILITY } from 'app/utils/constants/status.constants'
import {
  implementAdditionalStaticPage,
  selectStaticPages
} from 'app/utils/helpers/staticPage.helpers'

import FooterLinksBlock from './FooterLinksBlock'

const Logo = dynamic(() => import('app/assets/images/dark-logo.svg'))

interface FooterProps {
  isPoweredFooter?: boolean
}

const Footer = ({ isPoweredFooter }: FooterProps): JSX.Element => {
  const intl = useIntl()

  const { data, isSuccess } = useGetAllStaticPagesQuery({
    options: {
      select: (data) =>
        selectStaticPages({
          data,
          visibility: STATIC_PAGE_VISIBILITY.marketplace
        })
    }
  })

  const defaultStaticPages = [
    {
      name: intl.formatMessage({ defaultMessage: 'Learn more' }),
      static_pages: [
        ADDITIONAL_STATIC_PAGES.blog,
        ADDITIONAL_STATIC_PAGES.helpCenter
      ] as StaticByTitle[]
    },
    {
      name: intl.formatMessage({ defaultMessage: 'Get in touch' }),
      static_pages: [ADDITIONAL_STATIC_PAGES.contactUs] as StaticByTitle[]
    }
  ]

  const dataWithContactUsPage = implementAdditionalStaticPage({
    data,
    pages: [ADDITIONAL_STATIC_PAGES.contactUs],
    toColumnNumber: 3
  })

  const staticPagesQuery = implementAdditionalStaticPage({
    data: dataWithContactUsPage,
    pages: [ADDITIONAL_STATIC_PAGES.blog, ADDITIONAL_STATIC_PAGES.helpCenter],
    toColumnNumber: 2
  })

  const staticPages = isSuccess ? staticPagesQuery : defaultStaticPages

  return (
    <Box
      sx={{
        pt: { xs: 4, md: 8 },
        pb: 5,
        borderTop: { xs: 'none', md: '1px solid' },
        borderColor: { xs: 'none', md: 'stroke.main' }
      }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} md={3}>
            <Stack
              direction="column"
              spacing={2.5}
              sx={{ mb: { xs: 4, md: 0 } }}
            >
              <Stack direction="column" spacing={1}>
                {isPoweredFooter && (
                  <Text variant="subtitle2">
                    <FormattedMessage defaultMessage="Powered by" />
                  </Text>
                )}

                <Link href="/" aria-label="iFind logo - homepage link">
                  <Logo />
                </Link>
              </Stack>

              <Stack direction="column">
                <Text size={{ xs: 'subtitle1' }} sx={{ color: 'text.third' }}>
                  <FormattedMessage
                    defaultMessage="Online store {year}."
                    values={{ year: new Date().getFullYear() }}
                  />
                </Text>

                <Text size={{ xs: 'subtitle1' }} sx={{ color: 'text.third' }}>
                  <FormattedMessage defaultMessage="All rights are reserved." />
                </Text>
              </Stack>

              <SocialLinksBlock
                spacing={2}
                sx={{ display: { xs: 'none', md: 'block' } }}
              />
            </Stack>
          </Grid>

          {staticPages?.map((item, index) => (
            <Grid
              item
              key={item.name}
              xs={6}
              sm={4}
              md={3}
              sx={{
                display: { xs: 'block', md: 'flex' },
                justifyContent: 'center',
                ...(index === 2 && { mt: { xs: 5, sm: 0 } })
              }}
            >
              <FooterLinksBlock title={item.name} items={item.static_pages} />
            </Grid>
          ))}

          <Grid
            item
            xs={12}
            sx={{ mt: 5, display: { xs: 'block', md: 'none' } }}
          >
            <SocialLinksBlock spacing={2} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
